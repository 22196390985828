/* You can add global styles to this file, and also import other style files */
// Import library functions for theme creation.
// Import library functions for theme creation.
@import '@angular/material/theming';
@import 'assets/scss/bootstrap';

// Include non-theme styles for core.
@include mat-core();

@import 'assets/scss/components/mat-card';
@import 'assets/scss/components/cdk_accordion';
@import 'assets/scss/components/nav';


// Define your application's custom theme.
$primary: mat-palette($mat-blue);
$accent: mat-palette($mat-teal, 800);
$warn: mat-palette($mat-amber, 900);
$theme: mat-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn: $warn
    )
));

// Include theme styles for Angular Material components.
@include angular-material-theme($theme);

html, body {
    background-color: rgba($blue, .03);
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.sidenav {
    background-color: mat-color($primary, 50);

    * {
        color: mat-color($primary, 700);
    }
}

.mat-grid-list {
    margin: 1rem;
}

.mat-form-field {
    min-width: 300px;
}

.isLoading, .successButton {
    display: flex;
    justify-content: center;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1050;

    * {
        align-self: center;
    }

}

mat-cell, mat-header-cell, mat-footer-cell {
    &:last-of-type {
        justify-content: center;
        @media (max-width: 768px) {
            padding-right: 10px !important;
        }
    }

    &:first-of-type {
        @media (max-width: 768px) {
            padding-left: 10px !important;
        }
    }
}

.mat-flat-button {
    @media (max-width: 768px) {
        line-height: 24px !important;
        min-width: 40px !important;
        .mat-icon {
            font-size: 1rem;
            width: 1rem;
            height: 1rem;
        }
    }
}

.weekFooter {
    padding: 1rem;
}

.meal-list {

    &-card {
        margin-bottom: 1rem;

        &-title {
            font-size: 1rem !important;
            word-wrap: anywhere;
        }

        &-avatar {
            font-size: 36px !important;
            height: 40px !important;
            width: 40px !important;
        }
    }

    &-filter {
        margin: 1rem 0 .5rem;
    }
}