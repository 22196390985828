.meal-accordion {
    &-item {
        display: flex;
        flex-direction: column;
        background: $white;
        outline: none;

        &-header {
            border-bottom: 2px solid $gray-400;
            padding: 1rem;
            position: relative;
        }

        &:focus {
            outline: none;
            border: none;
        }
    }
}